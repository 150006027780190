import type { DailyInvoices } from 'src/types/api/extendedTypes'

import { SinaoStatusEnum } from '../../../types/api/extendedTypes'
import type { LocalFilter, ParticularFilter, ProFilters } from '../types'

export function filterDataLocally(
  isPro: boolean,
  data: DailyInvoices[],
  filters: Partial<LocalFilter>,
) {
  if (isPro) return filterProData(data, filters)
  return filterParticularData(data, filters)
}

function filterProData(data: DailyInvoices[], filters: Partial<ProFilters>) {
  const { contractId } = filters
  if (!contractId) return data
  return data.filter((invoice) => invoice?.pro_id === contractId)
}

function filterParticularData(
  data: DailyInvoices[],
  filters: Partial<ParticularFilter>,
) {
  const { ramoneur, type_paiement, sinaoStatus } = filters

  console.log('filters', filters.sinaoStatus)

  if (ramoneur && type_paiement && sinaoStatus) {
    const filteredData = data.filter(
      (inv) => inv.ramoneur === ramoneur && inv.type_paiement === type_paiement,
    )
    if (!sinaoStatus) return filteredData
    if (sinaoStatus) {
      return filteredData.filter((inv) => {
        if (sinaoStatus === SinaoStatusEnum.notBilled) {
          return inv.sinao_invoice_status === null
        }
        if (sinaoStatus === SinaoStatusEnum.billed) {
          return inv.sinao_invoice_status !== null
        }
        return inv.sinao_invoice_status === sinaoStatus
      })
    }
    return filteredData.filter((inv) => !inv.sinao_id)
  }

  if (ramoneur && type_paiement) {
    return data.filter(
      (inv) => inv.ramoneur === ramoneur && inv.type_paiement === type_paiement,
    )
  }
  if (ramoneur) {
    return data.filter((inv) => inv.ramoneur === ramoneur)
  }
  if (type_paiement) {
    return data.filter((inv) => inv.type_paiement === type_paiement)
  }
  if (sinaoStatus) {
    return data.filter((inv) => {
      if (sinaoStatus === SinaoStatusEnum.notBilled) {
        return inv.sinao_invoice_status === null
      }
      if (sinaoStatus === SinaoStatusEnum.billed) {
        return inv.sinao_invoice_status !== null
      }
      return inv.sinao_invoice_status === sinaoStatus
    })
  }

  return data
}
