import DateFnsUtils from '@date-io/date-fns'
import {
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
  TablePagination,
  CircularProgress,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import { useProfunctorState } from '@staltz/use-profunctor-state'
import type { Adress } from '@willig/types/api'
import { format, startOfYear } from 'date-fns'

import { useMemo, useState } from 'react'
import type { ListProps } from 'react-admin'
import { Link, Title, useNotify } from 'react-admin'

import { useForm, FormProvider } from 'react-hook-form'
import { useQuery } from 'react-query'
import { Stack } from 'src/components/UI/Stack'
import { concatAddress } from 'src/libs/concatNames'
import { useOne } from 'src/libs/useOne'
import type { DailyInvoices } from 'src/types/api/extendedTypes'

import { useInvoiceQueryBuilder } from '../../libs/invoiceQueryBuilder'

import {
  ParticularFilters,
  ProFilters,
  SendInvoiceButton,
  useTableHeader,
} from './Components'
import { InvoiceRow } from './Components/invoiceRow'
import { TotalFooter } from './Components/TotalFooter'
import { encodeApiFilters } from './encodeApiFilters'
import type { LocalFilter } from './types'
import { filterDataLocally } from './utils/filterDataLocally'

type Props = ListProps & {
  pro?: boolean
}

export function Invoices(props: Props) {
  const { pro = false } = props

  const today = new Date()

  const [localFilters, setLocalFilters] = useState<Partial<LocalFilter>>({
    isPro: pro,
    year: today,
    startDate: format(startOfYear(today), 'yyyy-MM-dd'),
    endDate: format(today, 'yyyy-MM-dd'),
  })

  const selectedStore = useProfunctorState<InvoicesSelectedState>({})

  const updateFilters = (value: Partial<LocalFilter>) => {
    selectedStore.setState(() => {
      return {}
    })
    setLocalFilters({ ...localFilters, ...value })
  }
  const notify = useNotify()

  const methods = useForm<LocalFilter>({
    defaultValues: {
      ...localFilters,
      contractId: undefined,
      isPro: Boolean(pro),
    },
  })

  const { handleSubmit } = methods

  const onFilterChange = () => {
    return handleSubmit(
      (data) => updateFilters(data as LocalFilter),
      (error) => {
        Object.values(error).forEach((err) => {
          return notify(`${err}`, 'warning', {}, false, 1500)
        })
      },
    )
  }

  const theme = useTheme()

  const title = pro ? 'Factures Pro' : 'Factures Particulier'

  return (
    <>
      <Stack
        css={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(4),
        }}
      >
        <Title title="Factures" />
        <Typography variant="h3">{title}</Typography>
        <Stack
          css={{
            display: 'flex',
            width: 'full',
            justifyContent: 'space-between',
          }}
        >
          <FormProvider {...methods}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <form
                onSubmit={handleSubmit(
                  (data) => updateFilters(data as LocalFilter),
                  (error) => {
                    Object.values(error).forEach((err) => {
                      return notify(`${err}`, 'warning', {}, false, 1500)
                    })
                  },
                )}
              >
                <Stack
                  css={{
                    display: 'flex',
                    gap: 8,
                  }}
                >
                  {pro ? (
                    <ProFilters onChange={onFilterChange} />
                  ) : (
                    <ParticularFilters onChange={onFilterChange} />
                  )}
                </Stack>
              </form>
            </MuiPickersUtilsProvider>
          </FormProvider>
          <Stack
            css={{
              display: 'flex',
              gap: 8,
            }}
          >
            <Button
              href={`/#/Invoices/pro/`}
              variant="contained"
              color={pro ? 'secondary' : 'primary'}
            >
              {'Professionnels'}
            </Button>
            <Button
              href={`/#/Invoices/`}
              variant="contained"
              color={pro ? 'primary' : 'secondary'}
            >
              {'Particuliers'}
            </Button>
          </Stack>
        </Stack>
        <InvoiceList
          filters={localFilters}
          isPro={pro}
          selectedStore={selectedStore}
        />
      </Stack>
    </>
  )
}

export type InvoiceSelectedState = {
  selected: boolean
  invoice?: DailyInvoices
}

export type InvoicesSelectedState = Record<
  string,
  InvoiceSelectedState | undefined
>

type Prps = {
  filters: Partial<LocalFilter>
  isPro: boolean
  selectedStore: ProfunctorState<InvoicesSelectedState>
}

function InvoiceList(props: Prps) {
  const { filters, isPro, selectedStore } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)
  const apiFilters = encodeApiFilters(filters)

  const invoiceQueryBuilder = useInvoiceQueryBuilder()

  const { data, isLoading } = useQuery(
    invoiceQueryBuilder.getDailyInvoices(apiFilters),
  )

  const [totalPrice, setTotalPrice] = useState({ total: 0, totalPage: 0 })

  const filteredDatas = useMemo(
    () => filterDataLocally(isPro, data ?? [], filters),
    [data, filters, isPro],
  )
  const displayedData = useMemo(() => {
    if (!data) return []
    const firstIndex = page * rowsPerPage
    const filteredDisplayedData = filteredDatas.slice(
      firstIndex,
      firstIndex + rowsPerPage,
    )

    const totalPricePage = filteredDatas.reduce(
      (acc, tt) => acc + Number(tt.price_ttc ?? 0),
      0,
    )
    const totalPage = filteredDisplayedData.reduce(
      (acc, tt) => acc + Number(tt.price_ttc ?? 0),
      0,
    )

    setTotalPrice({
      total: Math.round(totalPricePage * 100) / 100,
      totalPage: Math.round(totalPage * 100) / 100,
    })

    return filteredDisplayedData
  }, [data, filteredDatas, page, rowsPerPage])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function selectAllAddress() {
    selectedStore.setState((prevSelected) => {
      const result: InvoicesSelectedState = {}
      displayedData?.forEach((invoice) => {
        if (invoice?.sinao_id) {
          return (result[invoice.intervention_report_id!] = {
            ...prevSelected[invoice.intervention_report_id!],
            invoice,
            selected: false,
          })
        }
        if (prevSelected[invoice.intervention_report_id!]) {
          return (result[invoice.intervention_report_id!] = {
            ...prevSelected[invoice.intervention_report_id!],
            invoice,
            selected: true,
          })
        }
        result[invoice.intervention_report_id!] = {
          invoice,
          selected: true,
        }
      })

      return { ...prevSelected, ...result }
    })
  }

  function unselectAllAddress() {
    selectedStore.setState((prevSelected) => {
      const result: InvoicesSelectedState = {}
      Object.entries(prevSelected).forEach(([addressId, selectState]) => {
        if (result[addressId] && selectState) {
          result[addressId] = { ...selectState, selected: false }
        }
      })

      return result
    })
  }
  const isAllSelected = useMemo(() => {
    if (displayedData?.length === 0) return false
    const selectedDatas = displayedData?.filter((dt) => !dt.sinao_id)
    if (selectedDatas.length === 0) return false
    return selectedDatas.every((raf) => {
      return selectedStore.state[raf.intervention_report_id!]?.selected
    })
  }, [displayedData, selectedStore])

  const InvoiceHeaders = useTableHeader(isPro)

  return (
    <>
      <SendInvoiceButton
        invoiceIds={selectedStore.state}
        onSuccess={unselectAllAddress}
        isPro={isPro}
      />
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: 0 }}>
                <Checkbox
                  checked={isAllSelected}
                  onChange={() => {
                    if (!isAllSelected) return selectAllAddress()
                    unselectAllAddress()
                  }}
                />
              </TableCell>
              {InvoiceHeaders}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <Typography>Chargement</Typography>
            ) : data?.length === 0 ? (
              <Typography>Aucune données</Typography>
            ) : (
              displayedData?.map((raf) => (
                <InvoiceRow
                  isPro={isPro}
                  raf={raf}
                  key={raf.intervention_id!}
                  addressesSelectedStateStore={selectedStore}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        count={filteredDatas?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Éléments par page :"
      />
      <TotalFooter total={totalPrice.total} totalPage={totalPrice.totalPage} />
    </>
  )
}

export function AdressName(props: { id?: string }) {
  const { id } = props
  const { data: adressName, isLoading } = useOne<Adress>(
    'Adress',
    {
      id: id ?? 'null',
    },
    { enabled: Boolean(id) },
  )

  if (isLoading) return <CircularProgress size={24} />

  return (
    <Link to={`/adress/${adressName?.data.id}`} target="_blank">
      {concatAddress({
        rue: adressName?.data.rue,
        code_postal: adressName?.data.code_postal,
        ville: adressName?.data.ville,
        cote: adressName?.data.cote,
      } as Adress)}
    </Link>
  )
}
