import {
  TableRow,
  TableCell,
  Checkbox,
  Box,
  Button,
  Typography,
} from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import type { ProfunctorState } from '@staltz/use-profunctor-state'
import classNames from 'classnames'
import type { PropsWithChildren } from 'react'
import { useMemo, useState } from 'react'
import { Confirm } from 'react-admin'
import { useMutation } from 'react-query'
import { useInvoiceQueryBuilder } from 'src/libs/invoiceQueryBuilder'
import type {
  DailyInvoices,
  SinaoStatusEnum,
} from 'src/types/api/extendedTypes'

import type { InvoiceSelectedState, InvoicesSelectedState } from '../index'
import { AdressName } from '../index'

const useRowStyles = makeStyles(function (theme) {
  return {
    header: {},
    cell: {
      padding: theme.spacing(0),
    },
  }
})

type RafRowProps = {
  raf: DailyInvoices
  addressesSelectedStateStore: ProfunctorState<InvoicesSelectedState>
  isPro: boolean
}
export function InvoiceRow(props: RafRowProps) {
  const { raf, isPro, addressesSelectedStateStore } = props
  const {
    adresses_interventions,
    ramoneur,
    type_paiement,
    price_ttc,
    sinao_id,
    date_intervention,
    infos_contacts,
    invoice,
    sinao_invoice_status,
  } = raf

  const priceHt = invoice?.price_computed

  // price_ttc
  //   ? Number(price_ttc) * (1 - invoice.tva / 100)
  //   : undefined

  const { state, setState } = addressesSelectedStateStore.promap<
    InvoiceSelectedState | undefined
  >({
    get: function get(stateValue) {
      return stateValue[raf.intervention_report_id!]
    },
    set: function set(invoiceId, prevState) {
      return {
        ...prevState,
        [raf.intervention_report_id!]: invoiceId!,
      }
    },
  })

  function onChange() {
    setState((prevState) => {
      return {
        ...prevState,
        invoice: raf,
        selected: !prevState?.selected,
      }
    })
  }

  const styles = useRowStyles()

  const fullName = `${infos_contacts?.nom} ${infos_contacts?.prenom}`

  const roundTtc = price_ttc
    ? Math.round(Number(price_ttc) * 100) / 100
    : undefined

  const roundHt = priceHt ? Math.round(Number(priceHt) * 100) / 100 : undefined

  const isInvalidInvoice = useMemo(() => {
    return Boolean(sinao_id || !roundTtc)
  }, [roundTtc, sinao_id])

  if (isPro) {
    return (
      <>
        <TableRow className={classNames(styles.header)}>
          <Cell>
            <Checkbox
              checked={state?.selected ?? false}
              onChange={onChange}
              disabled={isInvalidInvoice}
            />
          </Cell>
          <SinaoId id={sinao_id} status={sinao_invoice_status} />
          <Cell>
            <AdressName id={adresses_interventions?.adresse_id} />
          </Cell>
          <Cell>{date_intervention}</Cell>
          <Cell>{date_intervention}</Cell>
          <Cell>{roundHt} €</Cell>
          <Cell>{roundTtc} €</Cell>
          <Cell>
            <RevertButton sinaoId={sinao_id} isPro />
          </Cell>
        </TableRow>
      </>
    )
  }
  return (
    <>
      <TableRow className={classNames(styles.header)}>
        <Cell>
          <Checkbox
            checked={state?.selected ?? false}
            onChange={onChange}
            disabled={isInvalidInvoice}
          />
        </Cell>
        <Cell>{fullName}</Cell>
        <SinaoId id={sinao_id} status={sinao_invoice_status} />
        <Cell>
          <AdressName id={adresses_interventions?.adresse_id} />
        </Cell>
        <Cell>{date_intervention}</Cell>
        <Cell>{ramoneur}</Cell>
        <Cell>{type_paiement}</Cell>
        <Cell>{roundHt}</Cell>
        <Cell>
          {!roundTtc ? (
            <Typography color="error">Pas de factures</Typography>
          ) : (
            price_ttc
          )}
        </Cell>
        <Cell>
          <RevertButton sinaoId={sinao_id} />
        </Cell>
      </TableRow>
    </>
  )
}

function Cell(props: PropsWithChildren<{}>) {
  const styles = useRowStyles()

  return <TableCell className={styles.cell}>{props.children}</TableCell>
}

const useStyles = makeStyles(function (theme) {
  return {
    root: {
      padding: 16,
      paddingTop: 4,
      paddingBottom: 4,
      borderRadius: 4,
      color: theme.palette.common.white,
      maxWidth: 'fit-content',
      fontWeight: 'bold',
    },
  }
})

type SinaoIdProps = {
  id?: string
  status?: SinaoStatusEnum
}

function SinaoId(props: SinaoIdProps) {
  const { id, status } = props
  const theme = useTheme()
  const backgroundColor = getSinaoBackgroundColor(theme, status)
  const { root } = useStyles()
  if (!id) return <TableCell />
  const label = 'fact_' + id
  return (
    <TableCell>
      <Box className={root} style={{ backgroundColor: backgroundColor }}>
        {label}
      </Box>
    </TableCell>
  )
}

type RevertButtonProps = {
  sinaoId?: string
  isPro?: Boolean
}

function RevertButton(props: RevertButtonProps) {
  const { sinaoId, isPro = false } = props

  const invoiceQueryBuilder = useInvoiceQueryBuilder()
  const { mutateAsync } = useMutation(invoiceQueryBuilder.revertInvoice())
  const [open, setOpen] = useState(false)

  const content = getContent(isPro)

  if (!sinaoId) return <></>
  return (
    <>
      <Confirm
        isOpen={open}
        title={`Annuler une facture`}
        content={content}
        onConfirm={() => {
          mutateAsync(sinaoId, { onSuccess: () => setOpen(false) })
        }}
        onClose={() => setOpen(false)}
      />
      <Button
        variant="text"
        color="secondary"
        size="small"
        onClick={() => setOpen(true)}
      >
        Annuler
      </Button>
    </>
  )
}

function getContent(isPro: Boolean) {
  if (isPro)
    return 'Ce brouillon peut éventuellement regrouper plusieurs interventions ! Si tel est le cas, supprimer ce brouillon supprimera également le lien pour les autres interventions du groupement'
  return "Cette facture est sur le point d'être annulée"
}

function getSinaoBackgroundColor(theme: Theme, status?: SinaoStatusEnum) {
  switch (status) {
    case 'draft':
      return theme.palette.grey[500]
    case 'paid':
      return theme.palette.primary.light
    case 'final':
      return theme.palette.warning.main
    default:
      return theme.palette.grey[500]
  }
}
