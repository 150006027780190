import type { User } from '@willig/types/api'
import { Picker } from 'src/components/Form'
import { useGetList } from 'src/libs/useGetList'

import { DatePicker } from '../../../components/Form/index'
import { SinaoStatusEnum } from '../../../types/api/extendedTypes'

export function ParticularFilters(props: { onChange: any }) {
  const { onChange } = props

  const { data: sweeps } = useGetList<User>('User', {
    filter: { '<access_level': [50], '>access_level': [9] },
    sort: { field: 'access_level', order: 'DESC' },
  })

  return (
    <>
      <Picker
        options={
          sweeps.map((sweep) => {
            return {
              value: sweep.login,
              label: sweep.login,
            }
          }) ?? []
        }
        name="ramoneur"
        label="Ramoneur"
        onChange={() => onChange()()}
      />
      <Picker
        options={[
          {
            value: 'cheque',
            label: 'Chèque',
          },
          {
            value: 'especes',
            label: 'Espèce',
          },
          {
            value: 'CB',
            label: 'Carte bancaire',
          },
          {
            value: 'impayé',
            label: 'Sans paiement',
          },
        ]}
        name="type_paiement"
        label="Paiement"
        onChange={() => onChange()()}
      />

      <DatePicker
        name="startDate"
        label="Début de période"
        onChange={() => onChange()()}
      />
      <DatePicker
        name="endDate"
        label="Fin de période"
        onChange={() => onChange()()}
      />
      <Picker
        options={[
          { value: SinaoStatusEnum.notBilled, label: 'Non facturé' },
          { value: SinaoStatusEnum.draft, label: 'Brouillon' },
          { value: SinaoStatusEnum.paid, label: 'Payée' },
          { value: SinaoStatusEnum.final, label: 'Facturée' },
          { value: SinaoStatusEnum.billed, label: 'Envoyé en facturation' },
        ]}
        name="sinaoStatus"
        label="Status Sinao"
        onChange={() => onChange()()}
      />
    </>
  )
}
